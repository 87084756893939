import React from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import logoImg from 'static/assets/img/LOGO24.png';
import { ERoutes } from 'static/data/ERoutes';

export const WelcomePage: React.FC = () => {
  const router = useIonRouter();

  const handleNext = () => {
    router.push(ERoutes.HOME, 'forward');
  };

  return (
    <IonPage>
      <IonContent>
        <div className="content-section flex flex-col justify-between items-center h-full">
          <img src={logoImg} alt="logo" className="w-[50%]" />
          <div className="flex flex-col items-start gap-4 w-full">
            <h2 className="text-center w-full">🥳 You&apos;re in!</h2>

            <button className="standard-btn w-full" onClick={handleNext}>
              Let&apos;s party!
            </button>
          </div>
          <div />
        </div>
      </IonContent>
    </IonPage>
  );
};
