import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { UserProfile } from '../apis/general';

type ProfileStore = {
  profile: UserProfile | null;
  setProfile: (profile: UserProfile) => void;
  resetProfile: () => void;
};

type ProfilePictureStore = {
  profilePicture: string | null;
  setProfilePicture: (profilePicture: string) => void;
};

export const useProfileStore = create<ProfileStore>()(
  persist(
    (set) => ({
      profile: null,
      setProfile: (profile) => set({ profile }),
      resetProfile: () => set({ profile: null }),
    }),
    { name: 'profile-storage' }
  )
);

export const useProfilePictureStore = create<ProfilePictureStore>()(
  persist(
    (set) => ({
      profilePicture: null,
      setProfilePicture: (profilePicture) => set({ profilePicture }),
    }),
    {
      name: 'profile-picture-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
