import { ArrayElement, supabase } from './supabaseClient';
import { signal } from '@preact/signals-react';
import { Tables } from '../types/database.types';

export const eventCategories = signal<Tables<'category'>[]>([]);
export const appEvents = signal<AppEvents>([]);
export const userEvents = signal<UserEvents>([]);
export const profileRewards = signal<Tables<'profile_rewards'> | null>(null);

/** Fetches all static data and puts it in the relevant global signals. */
export const fetchStaticData = async () => {
  supabase
    .from('category')
    .select('*')
    .order('order', { ascending: true })
    .then(({ data, error }) => {
      if (error) console.error('Error fetching categories:', error);
      else eventCategories.value = data;
    });

  fetchEvents().then((data) => (appEvents.value = data));
};

export type UserProfile = Awaited<ReturnType<typeof fetchUserProfile>>;
export const fetchUserProfile = async (authId: string) => {
  const { data, error } = await supabase.from('profile').select('*, profile_rewards(*)').eq('id', authId).single();

  if (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }

  return data;
};

export type AppEvents = Awaited<ReturnType<typeof fetchEvents>>;
export type AppEvent = ArrayElement<AppEvents>;
export const fetchEvents = async () => {
  const { data, error } = await supabase.from('event').select('*, category(*)').order('date', { ascending: true });

  if (error) {
    console.error('Error fetching events:', error);
    return [];
  }

  return data;
};

export type UserEvents = Awaited<ReturnType<typeof fetchUserEvents>>;
export type UserEvent = ArrayElement<UserEvents>;
export const fetchUserEvents = async (authId: string) => {
  const { data, error } = await supabase.from('ticket_holder').select('*, event(*)').eq('profile_id', authId);

  if (error) {
    console.error('Error fetching user events:', error);
    return [];
  }

  const validEvents = data.map((holder) => holder.event).filter((event) => !!event);
  console.log('User id: ', authId, ' Data: ', validEvents);

  return validEvents;
};

export const refetchProfileRewards = async (authId: string) => {
  const { data, error } = await supabase.from('profile_rewards').select('*').eq('profile_id', authId).single();

  if (error) {
    console.error('Error fetching profile rewards:', error);
    profileRewards.value = null;
    return null;
  }

  profileRewards.value = data;
  return data;
};
