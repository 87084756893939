import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Store = {
  likedEvents: string[];
  likeEvent: (eventId: string) => void;
  unlikeEvent: (eventId: string) => void;
  isEventLiked: (eventId: string) => boolean;
};

export const useLikedEventsStore = create<Store>()(
  persist(
    (set, getState) => ({
      likedEvents: [],
      likeEvent: (eventId: string) => set((state) => ({ likedEvents: [...state.likedEvents, eventId] })),
      unlikeEvent: (eventId: string) => set((state) => ({ likedEvents: state.likedEvents.filter((likedEventId) => likedEventId !== eventId) })),
      isEventLiked: (eventId: string) => getState().likedEvents.includes(eventId),
    }),
    {
      name: 'liked-events-storage',
    }
  )
);
