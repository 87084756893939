import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import logoImg from 'static/assets/img/LOGO24.png';
import { getArrowBackIcon, getULogo } from 'static/assets/icons';
import { useIonRouter } from '@ionic/react';
import { ERoutes } from 'static/data/ERoutes';

type HeaderProps = {
  className?: string;
  title?: string;
  onBack?: () => void;
};

export const Header: FC<HeaderProps> = ({ className = '', title, onBack }) => {
  const router = useIonRouter();

  return !title && !onBack ? (
    <div className={twMerge('w-full h-[40px] flex flex-row justify-between items-center', className)}>
      <img src={logoImg} alt="logo" className="w-[50%]" />
      <button className="wobble-animate" onClick={() => router.push(ERoutes.PROFILE)}>
        {getULogo('40px')}
      </button>
    </div>
  ) : (
    <div className={twMerge('w-full h-[40px] flex flex-row justify-between items-center', className)}>
      <div className="h-[40px] w-[40px] flex flex-row items-center justify-center bg-white/10 rounded-xl wobble-animate" onClick={() => (onBack ? onBack() : router.goBack())}>
        {getArrowBackIcon()}
      </div>
      <h3>{title}</h3>
      <div className="h-[40px] w-[40px]" />
    </div>
  );
};
