import React, { useEffect, useRef } from 'react';
import { format, isSameDay, isThisWeek, isYesterday, subDays } from 'date-fns';
import { da } from 'date-fns/locale';
import Compressor from 'compressorjs';

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let audioContext: AudioContext | null = null;

export function createPlaySoundFunction(sound: string) {
  let isPlaying = false;
  let audioBuffer: AudioBuffer | null = null;

  // Fetch and decode the audio file once
  fetch(sound)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => {
      // Create the AudioContext if it doesn't exist yet
      if (!audioContext) {
        console.log('Creating new audio buffer');
        audioContext = new ((window as any).AudioContext || (window as any).webkitAudioContext)();
      }
      return audioContext?.decodeAudioData(arrayBuffer);
    })
    .then((decodedAudioBuffer) => {
      if (decodedAudioBuffer) audioBuffer = decodedAudioBuffer;
    })
    .catch((e) => console.error(e));

  return () => {
    if (!isPlaying && audioBuffer && audioContext) {
      isPlaying = true;
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start(0);
      source.onended = () => {
        isPlaying = false;
      };
    }
  };
}

export const makeBGFullDiv = (image: string) => {
  return <div style={makeBGImgStyle(image)} className="h-[100vh] w-full fixed top-0 -z-50" />;
};

export const makeBGImgStyle = (image: string) => ({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${image})`,
});

export const formatRelativeDate = (date: Date, abbrMonth = false) => {
  const today = new Date();

  let strFormat = `do ${abbrMonth ? 'MMM' : 'MMMM'} '${'kl.'}' HH:mm`;
  if (isSameDay(date, today)) strFormat = 'HH:mm';
  else if (isYesterday(date)) strFormat = `'I går' '${'kl.'}' HH:mm`;
  else if (isSameDay(date, subDays(today, 2))) strFormat = `'I forgårs' '${'kl.'}' HH:mm`;

  return format(date, strFormat, { locale: da });
};

export const formatRelativeDateShort = (date: Date) => {
  const today = new Date();

  let strFormat = 'do MMM';
  if (isSameDay(date, today)) strFormat = 'HH:mm';
  else if (isThisWeek(date)) strFormat = 'eee';

  return format(date, strFormat, { locale: da });
};

export const copyToClipboard = (text: string) => {
  void navigator.clipboard.writeText(text);
};

export const countTextRows = (text: string): number => {
  // Split the input string into individual lines
  const lines: string[] = text.split('\n');

  // Count the number of non-empty lines and lines with only whitespace
  return lines.filter((line) => line.trim() !== '' || line === '').length;
};

export const smoothScrollTo = (elementQuery: string, options?: ScrollIntoViewOptions) => {
  const element = document.querySelector(elementQuery);
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center', ...options });
};

// Acceptable range of compression is usually between 0.6 and 1.
export const compressImageBlob = async (blob: Blob, quality: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(blob, {
      quality,
      maxHeight: 2056,
      maxWidth: 2056,
      success: (result) => resolve(result),
      error: (err) => reject(err),
    });
  });
};

export const loadBlobImage = async (url: string, format: string) => {
  // Convert the blob url to a blob object
  const response = await fetch(url);
  const imageData = await response.blob();
  const imageBlob = new Blob([imageData], { type: `image/${format}` });

  return imageBlob;
};

export const convertBlobToDataURL = (blob: Blob) => {
  return new Promise<string | null>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const result = fileReader.result;

      if (typeof result !== 'string') return resolve(null);

      return resolve(result);
    };
    fileReader.readAsDataURL(blob);
  });
};

export const getExtensionOfDataURL = (dataUrl: string) => {
  return dataUrl.substring(dataUrl.indexOf('/') + 1, dataUrl.indexOf(';'));
};

export const validateDate = (value: string | string[] | null | undefined) => {
  return typeof value === 'string' ? value : '';
};

export const mainDateFormat = (date: Date) => {
  // eslint-disable-next-line
  return format(date, "dd MMM, yyyy 'at' HH:mm");
};

export const shortestDateFormat = (date: Date, locale?: Locale) => {
  // eslint-disable-next-line
  return format(date, 'dd / MM / yyyy', { locale: locale });
};

export const longDateFormat = (date: Date, locale?: Locale) => {
  // eslint-disable-next-line
  return format(date, "eeee 'd.' do MMMM", { locale: locale });
};

export const longDateShortMonthFormat = (date: Date, locale?: Locale) => {
  // eslint-disable-next-line
  return format(date, "eeee 'd.' do MMM", { locale: locale });
};

export const longDateShortMonthFormatWithTime = (date: Date, locale?: Locale) => {
  // eslint-disable-next-line
  return format(date, "eeee 'd.' do MMM 'kl.' HH:mm", { locale: locale });
};

export const shortDateFormat = (date: Date, locale?: Locale) => {
  // eslint-disable-next-line
  return format(date, "eee 'd.' do MMM", { locale: locale });
};

export const hourMinuteFormat = (date: Date, locale?: Locale) => {
  // eslint-disable-next-line
  return format(date, 'HH:mm', { locale: locale });
};

export const ionTimepickerFormat = (date: Date) => {
  // eslint-disable-next-line
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const capitalizeFirstLetter = (str: string): string => {
  if (str.length === 0) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const handleAnimationEnd = (event: any) => {
  event.target.classList.remove('button-wobble');
};

export const wobbleAnimate = (event: any) => {
  document.addEventListener('animationend', handleAnimationEnd);

  const el = event.target;
  el.classList.add('button-wobble');
};

export const wobbleAnimateElement = (el: HTMLElement) => {
  document.addEventListener('animationend', handleAnimationEnd);
  el.classList.add('button-wobble');
};
