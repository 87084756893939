import React, { useState } from 'react';
import { IonContent, IonPage, useIonAlert, useIonRouter } from '@ionic/react';
import logoImg from 'static/assets/img/LOGO24.png';
import { ERoutes } from 'static/data/ERoutes';
import { MockUser } from 'static/data/mock-user';
import { supabase } from 'apis/supabaseClient';
import { useSignal } from '@preact/signals-react';
import { fetchUserProfile } from 'apis/general';
import { useProfileStore } from 'store/profile-store';
import { useAuthUserStore } from 'store/user-store';

const removeSpaces = (input: string) => input.replace(/\s+/g, '');

const maxLetters = 11;
export const PhoneAccessPage: React.FC = () => {
  const [presentAlert] = useIonAlert();

  const router = useIonRouter();
  const [phoneInput, setPhoneInput] = useState('+45');
  const isAuthenticating = useSignal(false);
  const { resetProfile, setProfile } = useProfileStore();
  const { setAuthUser } = useAuthUserStore();

  const handleNext = async () => {
    const fields = {
      email: MockUser.email,
      password: MockUser.password,
    };

    isAuthenticating.value = true;

    const { data, error } = await supabase.auth.signInWithPassword(fields);

    if (error) {
      console.error('Error signing in:', error);
      await presentAlert({
        header: 'Login failed',
        message: error?.message,
        buttons: ['OK'],
      });
      isAuthenticating.value = false;

      return;
    }

    if (data.user && data.user.aud === 'authenticated') {
      setAuthUser(data.user);
      console.log('Logged in. Fetching profile data...');
      const profileRes = await fetchUserProfile(data.user.id);

      if (!profileRes) {
        resetProfile();
        console.log('No profile found.');
      } else {
        console.log(profileRes);
        setProfile(profileRes);
      }
      isAuthenticating.value = false;
      setTimeout(() => router.push(ERoutes.WELCOME, 'forward'), 300);
    }
  };

  const onInput = (e: any) => {
    const input = removeSpaces(e.currentTarget.value); // Remove all spaces
    if (input.length > maxLetters) return;
    if (input.length > 0 && isNaN(Number(input.slice(1)))) return;
    if (input.length === 0) return setPhoneInput('+');

    // Format the input with spaces after every 2 characters
    const formattedInput = input.replace(/(\d{2})(?=\d)/g, '$1 ');

    setPhoneInput(formattedInput);
  };

  return (
    <IonPage>
      <IonContent>
        <div className="content-section flex flex-col justify-between items-center h-full">
          <img src={logoImg} alt="logo" className="w-[50%]" />
          <div className="flex flex-col items-start gap-4 w-full">
            <h2>Whats your number? 😉📱</h2>

            <div className="w-full flex flex-col gap-1">
              <label>
                Phone Number{' '}
                <span className="text-neutral-400 text-sm">
                  ({removeSpaces(phoneInput).length}/{maxLetters})
                </span>
              </label>
              <input className="standard-input" value={phoneInput} type="tel" autoComplete="tel" maxLength={15} onInput={onInput} />
            </div>

            <button className="standard-btn w-full" disabled={!phoneInput || removeSpaces(phoneInput).length !== maxLetters || isAuthenticating.value} onClick={handleNext}>
              Next
            </button>
          </div>
          <p>Terms & Privacy Policy</p>
        </div>
      </IonContent>
    </IonPage>
  );
};
