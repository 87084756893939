import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Header } from 'ui/components/Header';
import { FeedActivityCard } from 'ui/components/cards/FeedActivityCard';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { eventCategories, appEvents } from 'apis/general';
import { computed, signal } from '@preact/signals-react';

type FixedRow = {
  index: number;
  style: any;
};

const activeCategoryFilter = signal<string | null>(null);
const trySetCategoryFilter = (category: string) => {
  activeCategoryFilter.value = activeCategoryFilter.value === category ? null : category;
};

const filteredAppEvents = computed(() => {
  if (activeCategoryFilter.value === null) return appEvents.value;
  return appEvents.value.filter((event) => event.category?.id === activeCategoryFilter.value);
});

const Row = ({ index, style }: FixedRow) => {
  const item = filteredAppEvents.value[index];
  return (
    <div
      style={{
        ...style,
        paddingBottom: '25px',
      }}
    >
      <FeedActivityCard appEvent={item} />
    </div>
  );
};

export const HomePage: React.FC = () => {
  console.log('HomePage rendered.');

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <div className="content-section flex flex-col justify-start items-center h-full">
          <Header />

          <div className="flex flex-row gap-2 flex-nowrap overflow-x-scroll w-full mt-6 hidden-scrollbar">
            {eventCategories.value.map((category) => (
              <div
                key={category.title}
                onClick={() => trySetCategoryFilter(category.id)}
                className={`flex flex-col items-center p-1.5 aspect-square h-[65px] w-[65px] rounded-lg gap-2 wobble-animate ${
                  activeCategoryFilter.value === category.id ? 'bg-primary-tint' : ''
                }`}
              >
                <img className="pointer-events-none" src={category.image_url} alt={category.title} />
                <p className="inline-block text-nowrap text-xs pointer-events-none">{category.title}</p>
              </div>
            ))}
          </div>

          <div className="w-full h-[400px] relative mt-6 flex-1">
            <div className="absolute left-0 h-full w-full">
              <AutoSizer>
                {({ height, width }) => (
                  <List className="List hidden-scrollbar" height={height} width={width} itemSize={190} itemCount={filteredAppEvents.value.length}>
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
