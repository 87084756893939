export enum ERoutes {
  BASE = '/',
  LANDING = '/landing',
  PHONE_ACCESS = '/phone-access',
  WELCOME = '/welcome',
  HOME = '/home',
  MY_EVENTS = '/my-events',
  SHOW_TICKET = '/show-ticket',
  PROFILE = '/profile',
}
